<template>
  <el-main class="simple1-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        卡洛動燃燒你的卡路里
      </div>
      <p>2024年3月</p>
      <div class="image-container">
        <img :src="image" class="image" alt="開放水域泳渡安全 衍生精準訓練課程">
        <p class="image_desc">開放水域泳渡安全 衍生精準訓練課程</p>
        <p class="image_desc">圖片為2023線上游泳環台賽主視覺照片</p>
        <p class="image_desc">(圖片提供：快樂島)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">實證目標</div>
        <div class="content-desc">
          根據111年運動現況調查顯示，民眾常見運動項目中從事水域活動僅占全部的7.3%，相較於其他運動游泳具有極大的推廣空間，快樂島希望能讓更多民眾願意投入游泳，故舉辦兩場游泳活動，透過運動卡路里兌換點數，提升全民愛游泳運動之樂趣，促進游泳運動產業升級之目標。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動</div>
        <div class="content-desc">
          快樂島與頂冠運動行銷一同辦理「2023線上游泳環台賽」與「 2023日月潭萬人泳渡嘉年華」。
        </div>
        <div class="content-desc">
          於112年9月20日至11月5日，共完成累計8,500人次及2.5萬筆運動生理數據，其中包含游泳距離、消耗卡路里等運動生理數據，完整數據留存原始資料於卡洛動APP中，並已與運動數據公益平台串接。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用模式</div>
        <div class="content-desc">
          導入戶外運動安全軌跡定位系統於游泳賽事中，運用穿戴裝置收集移動軌跡、游泳里程、游泳速度等數據，設計個人精準訓練課程。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用效益</div>
        <div class="content-desc">
          透過數據分析提升戶外游泳的安全性與提升游泳數位加值服務體驗。
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11202',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/112-02.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple1-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
